<template>
  <div class="mb-4">
    <v-form
      ref="form"
      class="row"
    >
      <template
        v-for="(f, k) in fields"
      >
        <base-selector-autocomplete
          v-if="f.items"
          :key="k"
          class="col-2"
          :items="f.items"
          item-text="label"
          :label="f.label"
          @select="newSelect => f.value = newSelect._id"
        />
        <v-text-field
          v-else
          :key="k"
          v-model="f.value"
          class="col-2"
          :rules="f.rules || []"
          :label="f.label"
        />
      </template>
    </v-form>

    <v-btn
      @click="save"
    >
      Save proxy
    </v-btn>
    <v-col
      v-if="error"
      cols="6"
    >
      <v-alert
        type="error"
      >
        {{ error }}
      </v-alert>
    </v-col>
    <v-progress-linear
      v-if="loading"
      color="deep-purple accent-4"
      indeterminate
      rounded
      height="6"
    />
  </div>
</template>
<script>
  import BaseSelectorAutocomplete from '../base/selectors/BaseSelectorAutocomplete.vue';
  import { createProxy } from '@/api/proxiesMethods.js';
  const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  const ipInputRegex = /^([0-9]{0,3}\.){0,3}[0-9]{0,3}$/;
  const ipValidate = (v) => ipRegex.test(v.trim()) || 'Invalid ip';
  const required = (v) => !!v.length || 'Field is required';
  const portValidate = (v) => /^[0-9]*$/.test(v) || 'Invalid port';
  export default {
    name: 'AddPRoxy',
    components: { BaseSelectorAutocomplete },
    data () {
      return {
        loading: false,
        error: null,
        fields: {
          proxy_address: {
            label: 'Proxy address',
            value: '',
            rules: [ipValidate],
          },
          port: {
            label: 'Port',
            value: '',
            rules: [required, portValidate],
          },
          country_code: {
            label: 'Country Code',
            value: '',
            rules: [required],
          },
          username: {
            label: 'Username',
            value: '',
            rules: [required],
          },
          password: {
            label: 'Password',
            value: '',
            rules: [required],
          },
          provider: {
            label: 'Select Provider',
            value: '',
            items: [
              { _id: 'proxyseller', label: 'Proxy Seller' },
            ],
            rules: [required],
          },
        },
      };
    },
    methods: {
      async save () {
        if (this.loading) {
          return;
        }
        this.loading = true;
        this.error = null;
        try {
          if (this.$refs.form.validate()) {
            const proxy = Object.entries(this.fields).reduce((prev, [k, v]) => {
              prev[k] = v.value.trim();
              return prev;
            }, {});
            proxy.ports = { http: Number(this.fields.port.value) };
            await createProxy(proxy);
          }
        } catch (e) {
          this.error = e;
          console.log(this.error);
        } finally {
          this.loading = false;
        }
      },
      update (name, e) {
        const value = e.target.value;
        let goThrough = true;
        switch (name) {
        case 'ip':
          goThrough = ipInputRegex.test(value);
          break;
        case 'port':
          goThrough = /^[0-9]*$/.test(value);
          break;
        }

        if (goThrough) {
          this.$emit(`update:${name}`, value);
          this.error[name] = !value.length;
        } else {
          e.target.value = this[name];
        }
      },
    },
  };
</script>
<style>
</style>
